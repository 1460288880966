// import axios from 'axios'
import pdf2base64 from 'pdf-to-base64'

export default {
     name: 'PdfLecteur',

     data() {
          return {
               base64:  null
          }
     },

     props:{
          fichierEmplacement:{
               
          },
     },

     methods: {
          visualiserPdf() {
               document.getElementById('iFrame').src = `data:application/pdf;base64,${this.base64}`;
          },

          convertToBase64() {
               pdf2base64(this.fichierEmplacement)
                    .then(
                         (response) => {
                              this.base64 = response;
                              this.visualiserPdf()
                         }
                    )
                    .catch(
                         (error) => {
                              console.log(error); //Exepection error....
                         }
                    )

          }
     },

     watch:{
          fichierEmplacement:function(newValue){
               if(newValue){
                    this.convertToBase64();
               }
          } 
     },

     mounted() {
          this.convertToBase64();
     }

}