import PdfLecteur from '@/components/pdfLecteur/pdfLecteur.vue'
import axios from 'axios';
import communLangage from '@/commun/commun.langage.js';
import fichier from '@/utils/fichier.js'

export default {
     name: 'Documents',

     components: {
          PdfLecteur
     },

     data() {
          return {
               fichierAPrevisualiser: null,
               fichiers: [],
               texte: {}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          }
     },

     methods: {
          recupererTexte() {
               axios.get(process.env.BASE_URL + 'assets/textes/documents/documents.json').then(result => {
                    this.texte = this.langage == "fr" ? result.data.fr : result.data.en;

               });
          },
          recupererCV() {
               fichier.recupererfichiersCV().then((result) => {

                    this.fichiers = result.data;
                    this.fichierAPrevisualiser = this.fichiers[0]?.url;
               });
               
          }
     },

     watch: {
          langage: function () {
               this.recupererTexte();
          }
     },

     created() {
          this.recupererTexte();
          this.recupererCV();
     }
}